<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="注册账号">
                <el-input size="small" v-model="name"></el-input>
            </el-form-item>
            <el-form-item label="联系手机号">
                <el-input size="small" v-model="mobile"></el-input>
            </el-form-item>
            <el-form-item label="提交时间：">
                <el-date-picker v-model="create_time" @change="val => (val ? '' : (create_time = []))" size="small"
                    type="daterange" value-format="timestamp" range-separator="~" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList()" size="small">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }"
            :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="注册账号" align="center">
                <template v-slot="{ row }">
                    <span>{{ row.umobile }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="grade" label="反馈意见" align="center">
                <template v-slot="{ row }">
                    <span>{{ row.content }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="grade" label="联系手机号" align="center">
                <template v-slot="{ row }">
                    <span>{{ row.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column label="反馈图片" align="center">
                <template slot-scope="scope">
                    <el-image :src="pictures" style="width:100px;height:100px;"
                        v-for="(pictures, indexs) in scope.row.images_d" :preview-src-list="scope.row.images_d"
                        :key="indexs"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="提交时间" align="center">
                <template slot-scope="scope">
                    {{ getDateformat(scope.row.create_time) }}
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import { getDateformat } from '../../util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            name: '',
            mobile: '',
            list: [],
            create_time: [],
            total_number: 0,
            dialogVisible: false,
            form: {
                page: 1,
                rows: 10
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        getList () {
            let data = {
                keyword: this.name,
                mobile: this.mobile,
            }
            if (this.create_time.length) {
                data.start_time = this.create_time[0] / 1000;
                data.end_time = this.create_time[1] / 1000;
            }
            data.page = this.form.page
            data.rows = this.form.rows
            this.$axios
                .post(this.$api.repair.set.feedback, data)
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        this.total_number = res.result.total;
                        this.list = list;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
            } else {
                this.form.page = val;
            }
            this.getList();
        },
        cancelSearch () {
            this.name = '';
            this.mobile = '';
            this.create_time = [];
            this.getList();
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}
</style>
